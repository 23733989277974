import React, { useState, useEffect, useRef } from "react";
import { Form } from "antd";
import {
  Banner,
  ComponentsContainer,
  ProductPlansEnableAndContactContainer,
  PlanFormsContainer,
  MainContainer,
  FixedContainer,
} from "./styels";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CommonHaeder from "../../components/header/commonHeader/CommonHaeder";
import useGetData from "../../hooks/useGetData";
import { InstanceWithAuth } from "../../App";
import ReactTostify from "../../common/ReactTostify";
import Spinner from "../../common/Spinner";
import PlansSidebar from "../../components/temporaryLiveAnsweringDetails/plansSidebar/PlansSidebar";
import UserVisibility from "../../components/temporaryLiveAnsweringDetails/userVisibility/UserVisibility";
import DetailForm from "../../components/temporaryLiveAnsweringDetails/detailForm/DetailForm";
import DefaultEndDate from "../../components/temporaryLiveAnsweringDetails/defaultEndDate/DefaultEndDate";
import VoiceMailSettings from "../../components/temporaryLiveAnsweringDetails/voiceMailSettings/VoiceMailSettings";
import Swal from "sweetalert2";
import { handSelectCompanyFlowTab } from "../../redux/app/appSlice";

const TemporaryLiveAnsweringDetails = () => {
  const { contactId, companyId, profileId } = useParams();
  const [form] = Form.useForm();
  const { app, auth } = useSelector((state) => state);
  const navigate = useNavigate();
  const [profileDetails, setProfileDetails] = useState(null);

  const [preferredAreaCode, setPreferredAreaCode] = useState("");
  const [voicemailOption, setVoicemailOption] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [status, setStatus] = useState("");
  const [callHandling, setCallHandling] = useState("");

  const [useLiveAnsweringPlan, setUseLiveAnsweringPlan] = useState(false);
  const [liveAnsweringPlan, setLiveAnsweringPlan] = useState(null);

  const [active, setActive] = useState(1);
  const [activeSection, setActiveSection] = useState(1);
  const observer = useRef(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getCurrentDate = () => {
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}`;
  };

  useEffect(() => {
    const fetchNumberDetails = async () => {
      try {
        const response = await InstanceWithAuth.get(
          `live-answering/details/${profileId}`,
          {
            headers: { Authorization: `Bearer ${auth?.userObj?.api_token}` },
          }
        );
        if (response.status === 200 || response.status === 201) {
          setProfileDetails(response.data?.data);
        }
      } catch (error) {
        console.error("Failed to fetch number details", error);
      }
    };

    if (profileId) {
      fetchNumberDetails();
    }
  }, [profileId, auth]);

  useEffect(() => {
    if (profileDetails) {
      form.setFieldsValue({
        activation_date:
          profileDetails?.phone_number?.activation_date || getCurrentDate(),
        forward_calls_to: profileDetails?.phone_number?.forward_calls_to,
        virtual_phone_number:
          profileDetails?.phone_number?.virtual_phone_number,
        preferred_area_code: profileDetails?.phone_number?.preferred_area_code,
        voicemail_script: profileDetails?.phone_number?.voicemail_script,
        greeting_company_name: profileDetails?.greeting_company_name,
        notification_email: profileDetails?.notification_email,
        short_business_description: profileDetails?.short_business_description,
        special_instructions: profileDetails?.special_instructions,
        area_code: profileDetails?.preferred_area_code,
        // phone_number_status: profileDetails?.phone_number?.status,
        call_handling: profileDetails?.call_handling_type,
        voicemail_option: profileDetails?.phone_number?.voicemail_option,
      });
      setStatus(profileDetails.status);
      setCallHandling(profileDetails.call_handling_type);
    }
  }, [profileDetails]);

  const handleExternalSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("contact_id", contactId);
          formData.append("company_id", companyId);
          formData.append("virtual_phone_number", values?.virtual_phone_number);
          formData.append("activation_date", values?.activation_date);
          // formData.append("status", status);
          // if (useLiveAnsweringPlan) {
          //   formData.append("live_answering_profile_id", liveAnsweringPlan?.id);
          // }
          formData.append("call_handling_type", callHandling);
          formData.append("forward_calls_to", values?.forward_calls_to);
          formData.append("preferred_area_code", preferredAreaCode);

          formData.append(
            "greeting_company_name",
            values?.greeting_company_name
          );
          formData.append("notification_email", values?.notification_email);
          formData.append(
            "short_business_description",
            values?.short_business_description
          );
          formData.append("special_instructions", values?.special_instructions);
          formData.append("voicemail_option", voicemailOption);
          formData.append("voicemail_script", values?.voicemail_script);
          // formData.append("voicemail_file", selectedFile);

          const url = `live-answering/${profileId}`;

          const response = await InstanceWithAuth.put(url, formData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          });

          if (response?.status === 201) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: response?.data?.message,
              customClass: {
                confirmButton: "custom-swal-button",
              },
            });

            dispatch(handSelectCompanyFlowTab("7"));
            navigate(`/companies/${companyId}`);
          }
        } catch (error) {
          ReactTostify("error", error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  const scrollToSection = (id) => {
    setActive(id);
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find(
          (entry) => entry.isIntersecting
        )?.target;
        if (visibleSection) {
          setActiveSection(visibleSection.id);
        }
      },
      {
        rootMargin: "-40% 0px",
        scrollMargin: "-40% 0px",
        delay: 100,
      }
    );

    const sections = document.querySelectorAll(".scroll-section");

    sections.forEach((section) => {
      observer.current.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, []);

  const handleSubmit = (value) => {};

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "42px",
      }}
    >
      <CommonHaeder
        firstBcItem={"Live Answering"}
        firstBcIcon="live answering"
        firtBcUrl={`/companies/${companyId}/`}
        secondBcItem={`Live Answering Plan #${profileId}}`}
        secondBcIcon="live answering"
      ></CommonHaeder>
      <MainContainer collapsed={app?.sidebarCollapsed}>
        <Banner>
          <div className="label">
            {`Live Answering Plan #${profileId} ${
              profileDetails?.phone_number?.virtual_phone_number
                ? `(${profileDetails.phone_number.virtual_phone_number})`
                : ""
            }`}
          </div>

          <div className="btns">
            <div className="btns">
              <button className="cancel-btn" onClick={() => navigate(-1)}>
                <span className="btn-text">Cancel</span>
              </button>
              <button className="save-btn" onClick={handleExternalSubmit}>
                <span className="btn-text">
                  {loading ? <Spinner></Spinner> : "Save"}
                </span>
              </button>
            </div>
          </div>
        </Banner>

        <PlanFormsContainer>
          <PlansSidebar
            scrollToSection={scrollToSection}
            active={active}
            activeSection={activeSection}
          />
          <FixedContainer className="fixed">
            <Form
              className="main-form"
              onFinish={handleSubmit}
              form={form}
              requiredMark={false}
            >
              <ProductPlansEnableAndContactContainer>
                <ComponentsContainer>
                  <DetailForm
                    id={1}
                    status={status}
                    setStatus={setStatus}
                    preferredAreaCode={preferredAreaCode}
                    setPreferredAreaCode={setPreferredAreaCode}
                  />

                  {/* <DefaultEndDate
                    id={2}
                    contactId={contactId}
                    useLiveAnsweringPlan={useLiveAnsweringPlan}
                    setUseLiveAnsweringPlan={setUseLiveAnsweringPlan}
                    liveAnsweringPlan={liveAnsweringPlan}
                    setLiveAnsweringPlan={setLiveAnsweringPlan}
                  /> */}

                  <UserVisibility
                    id={2}
                    useLiveAnsweringPlan={useLiveAnsweringPlan}
                    callHandling={callHandling}
                    setCallHandling={setCallHandling}
                  />

                  {/* <VoiceMailSettings
                    id={3}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    voicemailOption={voicemailOption}
                    setVoicemailOption={setVoicemailOption}
                  /> */}
                </ComponentsContainer>
              </ProductPlansEnableAndContactContainer>
            </Form>
          </FixedContainer>
        </PlanFormsContainer>
      </MainContainer>
    </div>
  );
};

export default TemporaryLiveAnsweringDetails;
