import React, { useEffect } from "react";
import FourthtColumnContainer from "./styles";
import { Skeleton } from "antd";
import ColumnHerder from "./columnHerder/ColumnHerder";
import TicketDetails from "./ticketDetails/TicketDetails";

const FourthColumnDeatils = ({
  selectedEmailId,
  setSelectedEmailOrChat,
  showDrawerContactDrawer,
  onCloseContactDrawer,
  selectedEmails,
  setSelectedEmails,
  selectedFolder,
  setRefetchEmail,
  id,
  selectedCompanyId,
  refetchEmail,
  refetchData,
  loading,
  email,
}) => {
  // const {
  //   data: email,
  //   error,
  //   loading,
  //   refetchData,
  // } = useGetData(`support/emails/${selectedEmailId}`)

  console.log("selectedEmailId", selectedEmailId);

  useEffect(() => {
    if (setRefetchEmail) {
      setRefetchEmail(refetchData);
    }
  }, [ setRefetchEmail]);

  return (
    <FourthtColumnContainer>
      {loading ? (
        <div style={{ minWidth: "200px", padding: "12px" }}>
          <Skeleton active rows={15} />
        </div>
      ) : selectedEmailId !== null ? (
        <>
          <div className="header-10">
            <ColumnHerder
              selectedEmails={selectedEmails}
              setSelectedEmails={setSelectedEmails}
              selectedEmailId={selectedEmailId}
              setSelectedEmailOrChat={setSelectedEmailOrChat}
              email={email}
              selectedFolder={selectedFolder}
              id={id}
            />
          </div>

          <TicketDetails
            email={email}
            userID={email?.data?.contact_id}
            selectedEmailId={selectedEmailId}
            showDrawerContactDrawer={showDrawerContactDrawer}
            onCloseContactDrawer={onCloseContactDrawer}
            refetchData={refetchData}
            selectedCompanyId={selectedCompanyId}
            selectedFolder={selectedFolder}
            senderName={email?.data?.sender_name}
            refetchEmail={refetchEmail}
          />
        </>
      ) : null}
    </FourthtColumnContainer>
  );
};

export default FourthColumnDeatils;
