import React, { useState, useEffect } from "react";
import { FaEnvelope, FaRegTrashAlt, FaRegCommentDots } from "react-icons/fa";
import {
  SecondColumnContainer,
  HiddenCheckboxInput,
  StyledCheckbox,
  StyledCheckboxContainer,
} from "./styles";
import { Dropdown, Menu, Skeleton, Space } from "antd";
import moment from "moment";
import { DownOutlined } from "@ant-design/icons";
import useGetData from "../../../hooks/useGetData";
import DataNotFound from "../../../common/DataNotFound";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Swal from "sweetalert2";
import { InstanceWithAuth } from "../../../App";
import { useSelector } from "react-redux";
import Echo from "laravel-echo";
import { handleAddOpenConversationDetails } from "../../../redux/app/appSlice";
import { useDispatch } from "react-redux";
const SecondColumn = ({
  onEmailSelect,
  selectedEmailId,
  selectedFolder,
  status,
  setRefetchEmails,
  isInitialLoad,
  setIsInitialLoad,
  selectedEmails,
  setSelectedEmails,
  id,
  setSelectedCompanyId,
  searchParm,
  initialEmails,
  refetchEmails,
  order,
  setOrder,
  loading = { loading },
  setSelectedEmailOrChat,
}) => {
  const [hoveredEmailId, setHoveredEmailId] = useState(null);
  const [isFolderChange, setIsFolderChange] = useState(false);
  const [joinedChats, setJoinedChats] = useState([]);
  const [emails, setEmails] = useState("");
  const { auth, app } = useSelector((state) => state);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const echo = new Echo({
  //     broadcaster: 'pusher',
  //     key: 'local',
  //     cluster: 'your-app-cluster',
  //     wsHost: 'crm-stage.starthubcenters.com',
  //     wsPort: 6001,
  //     wssPort: 6001,
  //     forceTLS: true,
  //     enabledTransports: ['ws', 'wss'],
  //     disableStats: true,
  //   })
  //   echo.channel('chat.joined').listen('ChatJoined', (event) => {
  //     console.log('New event received:', event)
  //     setJoinedChats((prevsJoinedChats) => [...prevsJoinedChats, event])
  //   })
  //   echo.connector.pusher.connection.bind('connected', () => {
  //     console.log('Successfully connected to Pusher in chat listing')
  //   })
  //   return () => {
  //     echo.disconnect()
  //   }
  // }, [])

  useEffect(() => {
    const echo = new Echo({
      broadcaster: "pusher",
      key: "local",
      cluster: "your-app-cluster",
      wsHost: "crm-stage.starthubcenters.com",
      wsPort: 6001,
      wssPort: 6001,
      forceTLS: true,
      enabledTransports: ["ws", "wss"],
      disableStats: true,
    });
    echo.channel("chat.new").listen("ChatNew", (event) => {
      console.log("New event received:", event);
      setEmails((prevEmails) =>
        Array.isArray(prevEmails) ? [...prevEmails, event] : [event]
      );
    });
    echo.connector.pusher.connection.bind("connected", () => {
      console.log(
        "Successfully connected to Pusher to listing new chat request"
      );
    });
    return () => {
      echo.disconnect();
    };
  }, []);

  useEffect(() => {
    if (initialEmails?.data) {
      setEmails(initialEmails);
    }
  }, [initialEmails]);

  useEffect(() => {
    if (setRefetchEmails) {
      setRefetchEmails(refetchEmails);
    }
  }, [refetchEmails, setRefetchEmails]);

  useEffect(() => {
    setIsFolderChange(true);
    refetchEmails();
    setIsFolderChange(false);
  }, [selectedFolder]);

  const SortedAllOpenData = emails?.data?.data?.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  useEffect(() => {
    if (id && initialEmails?.data?.length >= 0) {
      const numericId = Number(id);
      // onEmailSelect(numericId)
      setIsInitialLoad(false);
    } else {
      if (isInitialLoad) {
        if (initialEmails?.data?.length >= 0) {
          // onEmailSelect(initialEmails?.data[0]?.id)
          setIsInitialLoad(false);
          setSelectedCompanyId(initialEmails?.data[0]?.company_id);
        }
      }
    }
  }, [initialEmails]);

  useEffect(() => {
    if (id && initialEmails?.data?.length > 0 && selectedFolder == "Chat") {
      const numericId = Number(id);
      // onEmailSelect(numericId)
      setIsInitialLoad(false);
    } else {
      if (isInitialLoad && selectedFolder == "Chat") {
        if (initialEmails?.data?.length > 0) {
          dispatch(handleAddOpenConversationDetails(initialEmails?.data[0]));
          // onEmailSelect(initialEmails?.data[0]?.id)
          setIsInitialLoad(false);
          setSelectedCompanyId(initialEmails?.data[0]?.company_id);
        }
      }
    }
  }, [initialEmails]);

  useEffect(() => {
    if (id && SortedAllOpenData?.length > 0 && selectedFolder == "All Open") {
      const numericId = Number(id);
      // onEmailSelect(numericId)
      setIsInitialLoad(false);
    } else {
      if (isInitialLoad && selectedFolder == "All Open") {
        if (SortedAllOpenData?.length > 0) {
          dispatch(handleAddOpenConversationDetails(SortedAllOpenData[0]));
          onEmailSelect(SortedAllOpenData[0]?.id);
          setIsInitialLoad(false);
          setSelectedCompanyId(SortedAllOpenData?.[0]?.company_id);
          if (selectedFolder === "All Open") {
            const firstEmail = SortedAllOpenData?.[0];
            if (firstEmail) {
              dispatch(handleAddOpenConversationDetails(firstEmail));
            }
          }
        }
      }
    }
  }, [initialEmails]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchEmails();
    }, 5000);

    return () => clearInterval(interval);
  }, [refetchEmails]);

  const getIcon = (type) => {
    switch (type) {
      case "email":
        return <FaEnvelope />;
      case "trash":
        return <FaRegTrashAlt />;
      case "chat":
        return <FaRegCommentDots />;
      default:
        return <FaEnvelope />;
    }
  };

  const getIconsForAllChat = (type) => {
    switch (type) {
      case "Email":
        return <FaEnvelope />;
      case "trash":
        return <FaRegTrashAlt />;
      case "LiveChat":
        return <FaRegCommentDots />;
      default:
        return <FaEnvelope />;
    }
  };

  const handleCheckboxChange = (email) => {
    setSelectedEmails((prevSelected) => {
      // Check if the email is already in the selected emails
      if (prevSelected.some((e) => e.id === email.id)) {
        // If the email is already selected, remove it
        return prevSelected.filter((e) => e.id !== email.id);
      } else {
        // If the email is not selected, add it
        return [...prevSelected, email];
      }
    });
  };

  const handleSelectAllChange = () => {
    const emailData =
      selectedFolder === "All Open" || selectedFolder === "All Closed"
        ? initialEmails?.data?.data
        : initialEmails?.data;

    setSelectedEmails((prevSelected) =>
      prevSelected?.length === emailData?.length
        ? []
        : emailData?.map((email) => email)
    );
  };

  const truncateText = (text, maxLength) =>
    text?.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  const formatTime = (time) => {
    const now = moment();
    const updatedAt = moment(time);

    if (now.diff(updatedAt, "minutes") < 60) {
      return `${now.diff(updatedAt, "minutes")}m`;
    } else if (now.diff(updatedAt, "hours") < 24) {
      return `${now.diff(updatedAt, "hours")}h`;
    } else if (now.diff(updatedAt, "days") === 1) {
      return "Yesterday";
    } else {
      return updatedAt.format("MMM D");
    }
  };

  const handleSortOrderChange = (newOrder) => {
    setOrder(newOrder);
  };

  const handleJoinChat = async (chatId) => {
    try {
      const response = await InstanceWithAuth.get(
        `live-chat/join-chat/${chatId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      );
      if (response.status == 200 || response.status == 201) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: response?.data?.message,
          customClass: {
            confirmButton: "custom-swal-button",
          },
        });
        refetchEmails();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: "custom-swal-button",
        },
      });
    }
  };

  const sortingMenu = (
    <Menu>
      <Menu.Item onClick={() => handleSortOrderChange("DESC")}>
        Newest
      </Menu.Item>
      <Menu.Item onClick={() => handleSortOrderChange("ASC")}>Oldest</Menu.Item>
    </Menu>
  );

  const handleAddConverstionDetails = (
    emailId,
    companyId,
    conversationsDetails
  ) => {
    onEmailSelect(emailId, companyId);
    dispatch(handleAddOpenConversationDetails(conversationsDetails));
  };

 

  return (
    <SecondColumnContainer>
      {loading && isInitialLoad ? (
        <Skeleton
          loading={isInitialLoad || isFolderChange}
          active
          style={{ margin: "10px", width: "auto" }}
        ></Skeleton>
      ) : (
        <>
          <div className="header">
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                gap: "7px",
              }}
            >
              <StyledCheckboxContainer>
                <HiddenCheckboxInput
                  checked={
                    selectedEmails?.length ===
                    (selectedFolder === "All Open" ||
                    selectedFolder === "All Closed"
                      ? initialEmails?.data?.data?.length
                      : initialEmails?.data?.length)
                  }
                  onChange={handleSelectAllChange}
                />
                <StyledCheckbox
                  checked={
                    selectedEmails?.length ===
                    (selectedFolder === "All Open" ||
                    selectedFolder === "All Closed"
                      ? initialEmails?.data?.data?.length
                      : initialEmails?.data?.length)
                  }
                  onChange={handleSelectAllChange}
                />
              </StyledCheckboxContainer>

              {selectedEmails?.length > 0 && (
                <span className="selected-mails">
                  {selectedEmails?.length + " " + "Selected"}
                </span>
              )}
            </div>

            {selectedEmails?.length <= 0 && (
              <Dropdown overlay={sortingMenu} trigger={["click"]}>
                <Space className="btn-title">
                  {order === "DESC" ? "Newest" : "Oldest"}
                  <MdOutlineKeyboardArrowDown
                    width="1rem"
                    height="1rem"
                    size="15px"
                  ></MdOutlineKeyboardArrowDown>
                </Space>
              </Dropdown>
            )}
          </div>
          <div className="email-list">
            {(selectedFolder == "All Open" || selectedFolder == "All Closed") &&
            emails?.data?.data?.length > 0 ? (
              SortedAllOpenData?.map((email) => (
                <div
                  key={email.id}
                  className={`email-item ${
                    email.id === selectedEmailId ? "selected" : ""
                  }`}
                  onClick={() =>
                    handleAddConverstionDetails(
                      email.id,
                      email.company_id,
                      email
                    )
                  }
                  onMouseEnter={() => setHoveredEmailId(email.id)}
                  onMouseLeave={() => setHoveredEmailId(null)}
                >
                  <div className="email-header">
                    {hoveredEmailId === email.id ||
                    selectedEmails.some((e) => e.id === email.id) ? (
                      <div className="email-checkbox">
                        <StyledCheckboxContainer>
                          <HiddenCheckboxInput
                            checked={selectedEmails.some(
                              (e) => e.id === email.id
                            )} // Check if the email is selected
                            onChange={() => handleCheckboxChange(email)} // Pass the full email object
                          />
                          <StyledCheckbox
                            checked={selectedEmails.some(
                              (e) => e.id === email.id
                            )} // Check if the email is selected
                            onChange={() => handleCheckboxChange(email)} // Pass the full email object
                          />
                        </StyledCheckboxContainer>
                      </div>
                    ) : (
                      <div
                        className={`email-icon ${
                          email.id === selectedEmailId
                            ? "selected-email-icon"
                            : ""
                        }`}
                      >
                        {selectedFolder == "All Open"
                          ? getIconsForAllChat(email?.Model)
                          : getIcon(
                              selectedFolder == "Chat" ? "chat" : email?.type
                            )}
                      </div>
                    )}

                    <div className="email-details">
                      <div className="email-top-row">
                        <span
                          className={
                            email?.read_status == 1 || email?.owner != null
                              ? "email-sender"
                              : "unread-email-sender"
                          }
                        >
                          {truncateText(
                            email?.Model == "SupportEmails"
                              ? email?.sender_name
                              : email?.contact_id != null
                              ? email?.contact_name
                              : email.guest_name,
                            app?.sidebarCollapsed ? 15 : 8
                          )}
                        </span>
                        <span
                          className={
                            email?.read_status == 1 || email?.owner != null
                              ? "email-time"
                              : "unread-email-time"
                          }
                        >
                          {formatTime(email.created_at)}
                        </span>
                      </div>
                      <div
                        className={
                          email?.read_status == 1
                            ? "email-subject"
                            : "unread-email-subject"
                        }
                      >
                        {email?.Model == "SupportEmails"
                          ? truncateText(email.subject, 15)
                          : truncateText(email?.owner, 15)}
                      </div>
                      <div className="email-preview">
                        {truncateText(
                          email.email_body?.replace(/<\/?[^>]+(>|$)/g, ""),
                          27
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : emails?.data?.length > 0 ? (
              emails?.data?.map((email) => (
                <div
                  key={email.id}
                  className={`email-item ${
                    email.id === selectedEmailId ? "selected" : ""
                  }`}
                  onClick={() =>
                    handleAddConverstionDetails(
                      email.id,
                      email.company_id,
                      email
                    )
                  }
                  onMouseEnter={() => setHoveredEmailId(email.id)}
                  onMouseLeave={() => setHoveredEmailId(null)}
                >
                  <div className="email-header">
                    {hoveredEmailId === email.id ||
                    selectedEmails.includes(email.id) ? (
                      <div className="email-checkbox">
                        <StyledCheckboxContainer>
                          <HiddenCheckboxInput
                            checked={selectedEmails?.includes(email.id)}
                            onChange={() => handleCheckboxChange(email.id)}
                          />
                          <StyledCheckbox
                            checked={selectedEmails?.includes(email.id)}
                            onChange={() => handleCheckboxChange(email.id)}
                          />
                        </StyledCheckboxContainer>
                      </div>
                    ) : (
                      <div
                        className={`email-icon ${
                          email.id === selectedEmailId
                            ? "selected-email-icon"
                            : ""
                        }`}
                      >
                        {getIcon(
                          selectedFolder == "Chat" ? "chat" : email?.type
                        )}
                      </div>
                    )}
                    <div className="email-details">
                      <div className="email-top-row">
                        <span
                          className={
                            email?.read_status == 1 || email?.owner != null
                              ? "email-sender"
                              : "unread-email-sender"
                          }
                        >
                          {selectedFolder == "Chat"
                            ? truncateText(
                                email?.contact_id != null
                                  ? email?.contact_name
                                  : email?.company_id != null
                                  ? email?.company_name
                                  : email?.guest_name,
                                15
                              )
                            : truncateText(
                                email.sender_name,
                                app?.sidebarCollapsed ? 15 : 8
                              )}
                        </span>
                        <span
                          className={
                            email?.read_status == 1 || email?.owner != null
                              ? "email-time"
                              : "unread-email-time"
                          }
                        >
                          {formatTime(email.created_at)}
                        </span>
                      </div>
                      <div
                        className={
                          email?.read_status == 1 || email?.owner != null
                            ? "email-subject"
                            : "unread-email-subject"
                        }
                      >
                        {selectedFolder == "Chat" && email.owner != null
                          ? truncateText(email.owner, 15)
                          : truncateText(email.subject, 15)}
                      </div>
                      <div className="email-preview">
                        {truncateText(
                          email.email_body?.replace(/<\/?[^>]+(>|$)/g, ""),
                          27
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-emails" style={{ marginTop: "55%" }}>
                <DataNotFound
                  label={
                    selectedFolder == "All Open"
                      ? "No conversation found"
                      : selectedFolder == "Chat"
                      ? "No chat found"
                      : selectedFolder == "All Closed"
                      ? "No conversation found"
                      : "No email Found"
                  }
                />
              </div>
            )}
          </div>
        </>
      )}
    </SecondColumnContainer>
  );
};

export default SecondColumn;
