import React, { useState } from "react";
import "./styles";
import { Container } from "./styles";
import { Form, Input, Select } from "antd";
import useGetData from "../../../hooks/useGetData";

const DetailForm = ({
  id,
  status,
  setStatus,
  preferredAreaCode,
  setPreferredAreaCode,
}) => {
  const [value, setValue] = React.useState(["Miami"]);

  const {
    data: areaCodes,
    loading: areaCodesLoading,
    error: areaCodesErro,
  } = useGetData(`area-codes`);

  const areaCodeOptions =
    areaCodes?.data?.map((item) => ({
      label: item?.area_code,
      value: item?.area_code,
    })) || [];

  const options = [
    {
      label: "Active",
      value: "1",
    },
    {
      label: "Inactive",
      value: "2",
    },
  ];

  const handleSelectChange = (value) => {
    const intValue = parseInt(value);
    setValue(intValue);
  };

  return (
    <Container id={id} className="scroll-section">
      <div className="title">General</div>
      <div className="form-container">
        {/* <div className="form-main">
          <div className="form">
            <Form.Item
              label="Virtual Phone Number"
              name="virtual_phone_number"
              className="form-items"
            >
              <Input className="input" placeholder="+12124567890" />
            </Form.Item>
          </div>
        </div> */}
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Greeting Company Name"
              name="greeting_company_name"
              className="form-items"
            >
              <Input className="input" placeholder="Greeting Company Name" />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Notification Email"
              name="notification_email"
              className="form-items"
            >
              <Input className="input" placeholder="Notification Email" />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Short Business Description"
              name="short_business_description"
              className="form-items"
            >
              <Input
                className="input"
                placeholder="Short Business Description"
              />
            </Form.Item>
          </div>
        </div>
        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Special Instructions"
              name="special_instructions"
              className="form-items"
            >
              <Input className="input" placeholder="Special Instructions" />
            </Form.Item>
          </div>
        </div>
        {/* <div className="form-main">
          <div className="form">
            <Form.Item
              label="Activation Date"
              name="activation_date"
              className="form-items"
            >
              <Input className="input" placeholder="28 October 2023" />
            </Form.Item>
          </div>
        </div> */}
        {/* <div className="form-main">
          <div className="form">
            <Form.Item
              label="Phone number Status"
              name="phone_number_status"
              className="form-items"
            >
              <Select
                showSearch
                placeholder="Phone number Status"
                className="location"
                // value={status}
                style={{
                  width: "100% !important",
                }}
                // onChange={setStatus}
                options={options}
                onChange={handleSelectChange}
              />
            </Form.Item>
          </div>
        </div> */}

        <div className="form-main">
          <div className="form">
            <Form.Item
              label="Area Code"
              name="area_code"
              className="form-items"
            >
              <Select
                disabled={areaCodesLoading}
                showSearch
                placeholder="Area code"
                className="location"
                value={preferredAreaCode}
                style={{
                  width: "100% !important",
                }}
                onChange={setPreferredAreaCode}
                // onChange={handleSelectChange}
                options={areaCodeOptions}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DetailForm;
