import React, { useState, useEffect, useRef } from "react";
import { Form } from "antd";
import {
  Banner,
  ComponentsContainer,
  ProductPlansEnableAndContactContainer,
  PlanFormsContainer,
  MainContainer,
  FixedContainer,
} from "./styels";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CommonHaeder from "../../components/header/commonHeader/CommonHaeder";
import { InstanceWithAuth } from "../../App";
import ReactTostify from "../../common/ReactTostify";
import Spinner from "../../common/Spinner";
import PlansSidebar from "../../components/TemporaryPhoneNumberDetails/plansSidebar/PlansSidebar";
import UserVisibility from "../../components/TemporaryPhoneNumberDetails/userVisibility/UserVisibility";
import DetailForm from "../../components/TemporaryPhoneNumberDetails/detailForm/DetailForm";
import DefaultEndDate from "../../components/TemporaryPhoneNumberDetails/defaultEndDate/DefaultEndDate";
import VoiceMailSettings from "../../components/TemporaryPhoneNumberDetails/voiceMailSettings/VoiceMailSettings";
import Swal from "sweetalert2";
import { handSelectCompanyFlowTab } from "../../redux/app/appSlice";
import useGetData from "../../hooks/useGetData";

const TemporaryPhoneNumberDetail = () => {
  const { contactId, companyId, numberId } = useParams();
  const [form] = Form.useForm();
  const { app, auth } = useSelector((state) => state);
  const navigate = useNavigate();
  const [numberDetails, setNumberDetails] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [useLiveAnsweringPlan, setUseLiveAnsweringPlan] = useState(false);
  const [active, setActive] = useState(1);
  const [activeSection, setActiveSection] = useState(1);
  const observer = useRef(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    data: liveAnsweringData,
    loading: liveAnsweringLoading,
    error: liveAnsweringError,
  } = useGetData(`live-answering?contact_id=${contactId}&dropdown=true`);

  const {
    data: companyData,
    loading: companyDataLoading,
    refetchData: refetchCompnayDetailsData,
  } = useGetData(`companies/${companyId}`);

  const liveAnsweringOptions =
    liveAnsweringData?.data?.map((item) => {
      const key = Object.keys(item)[0];
      return { label: item[key], value: parseInt(key, 10) };
    }) || [];

  const getCurrentDate = () => {
    const today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}`;
  };

  useEffect(() => {
    const fetchNumberDetails = async () => {
      try {
        const response = await InstanceWithAuth.get(
          `live-answering/phone-numbers/${numberId}`,
          {
            headers: { Authorization: `Bearer ${auth?.userObj?.api_token}` },
          }
        );
        if (response.status === 200 || response.status === 201) {
          setNumberDetails(response.data?.data);
        }
      } catch (error) {
        console.error("Failed to fetch number details", error);
      }
    };

    if (numberId) {
      fetchNumberDetails();
    }
  }, [numberId, auth]);

  useEffect(() => {
    if (numberDetails) {
      form.setFieldsValue({
        activation_date: numberDetails.activation_date || getCurrentDate(),
        forward_calls_to: numberDetails.forward_calls_to,
        virtual_phone_number: numberDetails.virtual_phone_number,
        preferred_area_code: numberDetails.preferred_area_code,
        voicemail_script: numberDetails.voicemail_script,
        status: numberDetails.status,
        call_handling: numberDetails.call_handling_type,
        voicemail_option: numberDetails.voicemail_option,
      });
      if (numberDetails?.live_answering_profile_id) {
        setUseLiveAnsweringPlan(true);
      }
      if (liveAnsweringData && numberDetails) {
        const profileId = numberDetails.live_answering_profile_id;
        const matchedItem = liveAnsweringData?.data?.find(
          (item) => Object.keys(item)[0] === String(profileId)
        );
        if (matchedItem) {
          const intValue = parseInt(matchedItem?.id);
          form.setFieldsValue({
            live_answering_plan: intValue,
          });
        }
      }
    }
  }, [numberDetails, liveAnsweringData, form]);

  const handleExternalSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          setLoading(true);
          const formData = new FormData();
          formData.append("contact_id", contactId);
          formData.append("company_id", companyId);
          formData.append("virtual_phone_number", values?.virtual_phone_number);
          formData.append("activation_date", values?.activation_date);
          formData.append("status", values?.status);
          if (useLiveAnsweringPlan) {
            formData.append(
              "live_answering_profile_id",
              values?.live_answering_plan
            );
          }
          formData.append("call_handling_type", values?.call_handling);
          formData.append("forward_calls_to", values?.forward_calls_to);
          formData.append("preferred_area_code", values?.preferred_area_code);
          formData.append("voicemail_option", values?.voicemail_option);
          formData.append("voicemail_script", values?.voicemail_script);
          formData.append("voicemail_file", selectedFile);

          const requestType = numberDetails ? "put" : "post";
          const url = `live-answering/phone-numbers${
            numberDetails ? `/${numberId}` : ""
          }`;

          const response = await InstanceWithAuth[requestType](url, formData, {
            headers: {
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
              "Content-Type": "multipart/form-data",
            },
          });

          if (response?.status === 201) {
            Swal.fire({
              icon: "success",
              title: "Success!",
              text: response?.data?.message,
              customClass: {
                confirmButton: "custom-swal-button",
              },
            });

            dispatch(handSelectCompanyFlowTab("6"));
            navigate(`/companies/${companyId}`);
          }
        } catch (error) {
          ReactTostify("error", error?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  const scrollToSection = (id) => {
    setActive(id);
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find(
          (entry) => entry.isIntersecting
        )?.target;
        if (visibleSection) {
          setActiveSection(visibleSection.id);
        }
      },
      {
        rootMargin: "-40% 0px",
        scrollMargin: "-40% 0px",
        delay: 100,
      }
    );

    const sections = document.querySelectorAll(".scroll-section");

    sections.forEach((section) => {
      observer.current.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, []);

  const handleSubmit = (value) => {};

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "42px",
      }}
    >
      <CommonHaeder
        firstBcItem={"Companies"}
        firstBcIcon={"companies"}
        secondBcItem={companyData?.data?.name}
        secondBcIcon="companies"
        thirdBcItem={"Phone Numbers"}
        thirdBcIcon="plains and products"
        fourthBcItem={
          numberDetails?.virtual_phone_number
            ? numberDetails?.virtual_phone_number
            : "Add New"
        }
        fourthBcIcon="plains and products"
      ></CommonHaeder>
      <MainContainer collapsed={app?.sidebarCollapsed}>
        <Banner>
          <div className="label">
            {numberDetails ? "Edit Phone Number" : "Add New Phone Number"}
          </div>
          <div className="btns">
            <div className="btns">
              <button className="cancel-btn" onClick={() => navigate(-1)}>
                <span className="btn-text">Cancel</span>
              </button>
              <button className="save-btn" onClick={handleExternalSubmit}>
                <span className="btn-text">
                  {loading ? <Spinner></Spinner> : "Save"}
                </span>
              </button>
            </div>
          </div>
        </Banner>

        <PlanFormsContainer>
          <PlansSidebar
            scrollToSection={scrollToSection}
            active={active}
            activeSection={activeSection}
          />
          <FixedContainer className="fixed">
            <Form
              className="main-form"
              onFinish={handleSubmit}
              form={form}
              requiredMark={false}
            >
              <ProductPlansEnableAndContactContainer>
                <ComponentsContainer>
                  <DetailForm id={1} />

                  <DefaultEndDate
                    id={2}
                    contactId={contactId}
                    useLiveAnsweringPlan={useLiveAnsweringPlan}
                    setUseLiveAnsweringPlan={setUseLiveAnsweringPlan}
                    numberDetails={numberDetails}
                    liveAnsweringData={liveAnsweringData}
                    liveAnsweringLoading={liveAnsweringLoading}
                    liveAnsweringOptions={liveAnsweringOptions}
                  />

                  <UserVisibility
                    id={3}
                    useLiveAnsweringPlan={useLiveAnsweringPlan}
                  />

                  <VoiceMailSettings
                    id={4}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                  />
                </ComponentsContainer>
              </ProductPlansEnableAndContactContainer>
            </Form>
          </FixedContainer>
        </PlanFormsContainer>
      </MainContainer>
    </div>
  );
};

export default TemporaryPhoneNumberDetail;
