import React, { useEffect, useState } from 'react'
import { ListWrapper } from './styles'
import ChatCard from './chatCard/ChatCard'
import LiveChatCards from './chatCard/LiveChatCards'
import { useSelector } from 'react-redux'
import SpaceCard from '../../chatWithManager/spaceCard/SpaceCard'
import SessionCard from '../../chatWithManager/sessionCard/SessionCard'

const ContractedChats = () => {
  const [emailIds, setEmailIds] = useState([])
  const { auth, app } = useSelector((state) => state)

  useEffect(() => {
    const storedEmailIds =
      JSON.parse(localStorage.getItem('emailIdsArray')) || []
    setEmailIds(storedEmailIds)
  }, [])

  useEffect(() => {
    const handleStorageChange = () => {
      const storedEmailIds =
        JSON.parse(localStorage.getItem('emailIdsArray')) || []
      setEmailIds(storedEmailIds)
    }

    window.addEventListener('localStorageChange', handleStorageChange)
    return () => {
      window.removeEventListener('localStorageChange', handleStorageChange)
    }
  }, [])

  const removeEmailFromList = (emailId) => {
    const updatedEmailIds = emailIds?.filter((id) => id !== emailId)
    setEmailIds(updatedEmailIds)
    localStorage.setItem('emailIdsArray', JSON.stringify(updatedEmailIds))
  }

  return (
    <ListWrapper>
      <>
        {app?.currentActiveChatThread && app?.showContractedChat ? (
          <LiveChatCards emailId={app?.currentActiveChatThread}></LiveChatCards>
        ) : null}

        {emailIds?.length > 0
          ? emailIds?.map((emailId) => (
              <ChatCard
                key={emailId}
                emailId={emailId}
                removeEmailFromList={removeEmailFromList}
              />
            ))
          : null}

        {app?.internalChatsSpaces?.length > 0
          ? app?.internalChatsSpaces.map((space) => (
              <SpaceCard key={space?.id} space={space} />
            ))
          : null}

        {app?.internalChatsSessions?.length > 0
          ? app?.internalChatsSessions.map((session) => (
              <SessionCard key={session?.id} session={session} />
            ))
          : null}
      </>
    </ListWrapper>
  )
}

export default ContractedChats
