import React, { useEffect, useState } from 'react'
import ChatInputComponentContainer from './styles'
import MessageBody from './messageBody/MessageBody'
import CommentBody from './commentBody/CommentBody'
import { MdDragHandle } from 'react-icons/md'
import { useSelector } from 'react-redux'
const ChatInputComponent = ({
  onSend,
  handleCommentSend,
  savingComment,
  email,
  sendingMail,
  selectedTab,
  setSelectedTab,
  editingNote,
  selectedFolder,
  forwardMailDetails,
  isForwardMail,
  handleReplyEmail,
  clearInputtext,
  forwardFiles,
  setForwardFiles,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [formData, setFormData] = useState({
    to: [],
    cc: [],
    bcc: [],
    message: '',
  })

  const [commentFormData, setCommentFormData] = useState({
    comment: '',
  })
  const [containerHeight, setContainerHeight] = useState(300)
  const [isDragging, setIsDragging] = useState(false)
  const [startY, setStartY] = useState(0)
  const [startHeight, setStartHeight] = useState(0)
  const [value, setValue] = useState('')
  const { app } = useSelector((state) => state)
  useEffect(() => {
    if (editingNote) {
      setValue(editingNote.notes)
    } else {
      setValue('')
    }
  }, [editingNote])

  const handleFileSelect = (files) => {
    setSelectedFiles(files)
  }

  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value })
  }

  const handleSend = () => {
    onSend({ ...formData, files: selectedFiles })
    setSelectedFiles([])
  }

  const handleCommentInputChange = (name, value) => {
    setCommentFormData({ ...commentFormData, [name]: value })
  }

  const handleSendComment = () => {
    handleCommentSend({ ...commentFormData, files: selectedFiles })
    setCommentFormData({ comment: '' })
    setSelectedFiles([])
    setValue('')
  }

  const handleDragStart = (e) => {
    setIsDragging(true)
    setStartY(e.clientY)
    setStartHeight(containerHeight)
  }

  const handleDrag = (e) => {
    if (!isDragging) return
    const diffY = startY - e.clientY
    setContainerHeight(startHeight + diffY)
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }
  useEffect(() => {
    setValue('')
  }, [clearInputtext])
  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleDrag)
      document.addEventListener('mouseup', handleDragEnd)
    } else {
      document.removeEventListener('mousemove', handleDrag)
      document.removeEventListener('mouseup', handleDragEnd)
    }
    return () => {
      document.removeEventListener('mousemove', handleDrag)
      document.removeEventListener('mouseup', handleDragEnd)
    }
  }, [isDragging])

  return (
    <ChatInputComponentContainer
      style={{
        height: `${containerHeight}px`,
        filter:
          app?.allOpenConversationDetails?.owner == null &&
          selectedFolder != 'Inbox' &&
          app?.allOpenConversationDetails?.Model == 'LiveChat'
            ? 'blur(1px)'
            : '',
        pointerEvents:
          app?.allOpenConversationDetails?.owner == null &&
          selectedFolder != 'Inbox' &&
          app?.allOpenConversationDetails?.Model == 'LiveChat'
            ? 'none'
            : '',
        opacity:
          app?.allOpenConversationDetails?.owner &&
          (selectedFolder != 'Inbox') == null &&
          app?.allOpenConversationDetails?.Model == 'LiveChat'
            ? '0.5'
            : '',
      }}
    >
      <div className="tabs">
        <span className="drag-button" onMouseDown={handleDragStart}>
          <MdDragHandle size={30} color="var(--Dark-Gray-100, #2b3746)" />
        </span>

        <div
          className={`tab ${selectedTab === 'Email' ? 'active' : ''}`}
          onClick={() => setSelectedTab('Email')}
        >
          {selectedFolder == 'Chat' ||
          app?.allOpenConversationDetails?.Model == 'LiveChat'
            ? 'Live Chat'
            : 'Email'}
        </div>
        <div
          className={`tab ${selectedTab === 'Comment' ? 'active' : ''}`}
          onClick={() => setSelectedTab('Comment')}
        >
          Comment
        </div>
      </div>

      {selectedTab === 'Email' && (
        <MessageBody
          onFileSelect={handleFileSelect}
          onInputChange={handleInputChange}
          handleSend={handleSend}
          email={email}
          sendingMail={sendingMail}
          selectedFolder={selectedFolder}
          forwardMailDetails={forwardMailDetails}
          isForwardMail={isForwardMail}
          handleReplyEmail={handleReplyEmail}
          clearInputtext={clearInputtext}
          forwardFiles={forwardFiles}
          setForwardFiles={setForwardFiles}
        />
      )}
      {selectedTab === 'Comment' && (
        <CommentBody
          onFileSelect={handleFileSelect}
          onInputChange={handleCommentInputChange}
          handleSend={handleSendComment}
          savingComment={savingComment}
          editingNote={editingNote}
          value={value}
          setValue={setValue}
        />
      )}
    </ChatInputComponentContainer>
  )
}

export default ChatInputComponent
