import React, { useState } from "react";
import { Container } from "./styles";
import "./style.css";
import { Form, Input, Switch, Checkbox, DatePicker, Select } from "antd";
import useGetData from "../../../hooks/useGetData";

const DefaultEndDate = ({
  id,
  useLiveAnsweringPlan,
  setUseLiveAnsweringPlan,
  liveAnsweringData,
  liveAnsweringOptions,
}) => {
  const [liveAnsweringPlan, setLiveAnsweringPlan] = useState(null);


  const handlePlanChange = (value) => {
    const selectedItem = liveAnsweringData?.data?.find(
      (item) => Object.keys(item)[0] === value
    );
    const intValue = parseInt(selectedItem?.id);
    setLiveAnsweringPlan(intValue);
  };

  const handleOneTimeProductChange = () => {
    setUseLiveAnsweringPlan(!useLiveAnsweringPlan);
  };

  return (
    <Container id={id} className="scroll-section">
      <div className="form-main">
        <div className="title">Live Answering Plan</div>

        <div className="radio-container">
          <span className="default-label">
            Do you want to user live answering plan
          </span>
          <Form.Item valuePropName="checked" name="is_one_time">
            <Switch onChange={handleOneTimeProductChange} />
          </Form.Item>
        </div>
      </div>

      <div className="form-main">
        <div className="form">
          <Form.Item
            label="Live Answering Plans"
            name="live_answering_plan"
            className="form-items"
          >
            <Select
              showSearch
              placeholder="Live Answering Plans"
              className="location"
              style={{
                width: "100% !important",
              }}
              onChange={handlePlanChange}
              options={liveAnsweringOptions}
              // loading={liveAnsweringLoading}
              disabled={!useLiveAnsweringPlan}
            />
          </Form.Item>
        </div>
      </div>
    </Container>
  );
};

export default DefaultEndDate;
