import React, { useState, useEffect } from 'react'
import { FaUserCircle } from 'react-icons/fa'
import ChatHeaderContainer from './styles'
import ChatHeaderDropDown from '../chatHeaderDropDown/ChatHeaderDropDown'
import Avatar from './avatar/avatar'
import { InstanceWithAuth } from '../../../../App'
import Swal from 'sweetalert2'
import { Popconfirm } from 'antd'
import { IoMdContract } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import Echo from 'laravel-echo'
import { handleUpdateChatOwner } from '../../../../redux/app/appSlice'
import {
  handleAddCurrentActiveChatThread,
  handleAddCurrentActiveChatTab,
  handleShowContractedChat,
} from '../../../../redux/app/appSlice'

const ChatHeader = ({
  selectedEmailId,
  setCurrentEmployeeImage,
  currentEmployeeImage,
  email,
  selectedFolder,
}) => {
  const dispatch = useDispatch()

  const { app, auth } = useSelector((state) => state)
  const [joinedChats, setJoinedChats] = useState([])
  const [joinigChat, setJoinigChat] = useState(false)
  const addEmailIdToLocalStorage = (emailId) => {
    const savedEmailIds =
      JSON.parse(localStorage.getItem('emailIdsArray')) || []
    if (!savedEmailIds.includes(emailId)) {
      savedEmailIds.push(emailId)
      localStorage.setItem('emailIdsArray', JSON.stringify(savedEmailIds))

      const event = new Event('localStorageChange')
      window.dispatchEvent(event)
    }
  }
  const addActiveChatToContractedChat = (chatDetials) => {
    dispatch(handleAddCurrentActiveChatThread(chatDetials))
    dispatch(handleAddCurrentActiveChatTab(selectedFolder))
    dispatch(handleShowContractedChat(true))
    const event = new Event('localStorageChange')
    window.dispatchEvent(event)
  }

  useEffect(() => {
    const echo = new Echo({
      broadcaster: 'pusher',
      key: 'local',
      cluster: 'your-app-cluster',
      wsHost: 'crm-stage.starthubcenters.com',
      wsPort: 6001,
      wssPort: 6001,
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
      disableStats: true,
    })
    echo.channel('chat.joined').listen('ChatJoined', (event) => {
      console.log('New event received:', event)
      setJoinedChats((prevsJoinedChats) => [...prevsJoinedChats, event])
    })
    echo.connector.pusher.connection.bind('connected', () => {
      console.log('Successfully connected to Pusher in chat listing')
    })
    return () => {
      echo.disconnect()
    }
  }, [])
  const handleJoinChat = async (chatId) => {
    setJoinigChat(true)
    try {
      const response = await InstanceWithAuth.get(
        `live-chat/join-chat/${chatId}`,

        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      )
      if (response.status == 200 || response.status == 201) {
        dispatch(
          handleUpdateChatOwner({
            owner: `${auth?.userObj?.first_name} ${auth?.userObj?.last_name}`,
          })
        )
        setJoinigChat(false)
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response?.data?.message,
          customClass: {
            confirmButton: 'custom-swal-button',
          },
        })
      }
    } catch (error) {
      setJoinigChat(false)

      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: error?.response?.data?.message,
        customClass: {
          confirmButton: 'custom-swal-button',
        },
      })
    }
  }
  return (
    <ChatHeaderContainer>
      <div className="wrapper">
        <span className="title">Owner</span>
        <div className="avatar-name-container">
          <Avatar currentEmployeeImage={currentEmployeeImage} />
          {/* 
          {(selectedFolder == 'All Open' &&
            app?.allOpenConversationDetails?.Model == 'LiveChat') ||
          selectedFolder == 'Chat' ? (
            <div className="title owner-name">
              {app?.allOpenConversationDetails?.owner}
            </div>
          ) : ( */}
          <ChatHeaderDropDown
            selectedEmailId={selectedEmailId}
            setCurrentEmployeeImage={setCurrentEmployeeImage}
            email={email}
          ></ChatHeaderDropDown>
          {/* )} */}
        </div>
      </div>

      <div style={{ display: 'flex', gap: '5px' }}>
        {app?.allOpenConversationDetails &&
        app?.allOpenConversationDetails?.owner == null &&
        app?.allOpenConversationDetails?.Model == 'LiveChat' &&
        !joinedChats?.some(
          (obj) => obj?.liveChat?.id == app?.allOpenConversationDetails?.id
        ) ? (
          <button
            onClick={() => handleJoinChat(app?.allOpenConversationDetails?.id)}
            className="join-btn"
          >
            {joinigChat ? 'Joining Chat' : 'Joissn Chat'}
          </button>
        ) : null}
        <button
          onClick={() =>
            selectedFolder == 'Chat' ||
            (selectedFolder == 'All Open' &&
              app?.allOpenConversationDetails?.Model == 'LiveChat')
              ? addActiveChatToContractedChat(selectedEmailId)
              : addEmailIdToLocalStorage(selectedEmailId)
          }
        >
          <IoMdContract size={18} />
        </button>
      </div>
    </ChatHeaderContainer>
  )
}

export default ChatHeader
