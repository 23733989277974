import React, { useState, useEffect, useRef } from "react";
import { AiOutlineDelete } from "react-icons/ai"; // Trash icon
import { MdReportProblem } from "react-icons/md"; // Spam icon
import "./styles";
import { Container, AvatarPlaceholder } from "./styles"; // Import new styled component
import { InstanceWithAuth } from "../../../../App";
import Swal from "sweetalert2";
import { Popconfirm } from "antd";
import { PiArrowArcRightBold } from "react-icons/pi";
import { LuExternalLink } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import NameAvatar from "../../../../common/NameAvatar";
const ColumnHeader = ({
  selectedEmailId,
  refetchEmails,
  setSelectedEmailOrChat,
  email,
  showDrawerContactDrawer,
  selectedEmails,
  selectedFolder,
}) => {
  const navigate = useNavigate();
  const { app } = useSelector((state) => state);
  const firstName = email?.data?.contact?.first_name || "";
  const lastName = email?.data?.contact?.last_name || "";
  const senderName = email?.data?.sender_name || "";
  const avatar = email?.data?.contact?.avatar;
  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : "";
    const lastInitial = lastName ? lastName[0].toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };
  const getUnknownInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : "";
    const lastInitial = lastName ? lastName[0].toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  const TruncateText = ({ text }) => {
    const maxLength = 20;
    let truncatedText = text?.substring(0, maxLength);
    if (truncatedText?.length < text?.length) {
      truncatedText += "...";
    }
    return <span title={text}>{truncatedText}</span>;
  };
  console.log(
    app?.allOpenConversationDetails,
    "app?.allOpenConversationDetails"
  );
  let getInitialsName = (name) => {
    const names = name?.split(" ");
    const initials = names?.map((n) => n[0])?.join("");
    return initials?.toUpperCase();
  };
  return (
    <Container>
      {app?.allOpenConversationDetails?.Model == "SupportEmails" ? (
        <>
          {app?.allOpenConversationDetails?.is_unknown_visitor ? (
            <>
              {avatar ? (
                <img src={avatar} alt="Contact Avatar" />
              ) : (
                <AvatarPlaceholder>
                  {getUnknownInitials("Unknown", "Visitor")}
                </AvatarPlaceholder>
              )}
              <div className="details-div">
                <span className="contact-name">Unknown Visitor</span>
                <div className="bottom-line-div">
                  <span
                    onClick={showDrawerContactDrawer}
                    style={{ cursor: "pointer" }}
                    className="company-name"
                  >
                    + Add contact
                  </span>
                  <span className="dot"></span>
                  {app?.allOpenConversationDetails?.closed_at ? (
                    <>
                      <span className="from">Closed on</span>
                      <span className="from">
                        {formatDate(app?.allOpenConversationDetails?.closed_at)}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="from">Opened on</span>
                      <span className="from">
                        {formatDate(
                          app?.allOpenConversationDetails?.created_at
                        )}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              {avatar ? (
                <img src={avatar} alt="Contact Avatar" />
              ) : (
                <AvatarPlaceholder>
                  <NameAvatar
                    val={30}
                    userName={app?.allOpenConversationDetails?.sender_name}
                    fontSize={16}
                  ></NameAvatar>
                </AvatarPlaceholder>
              )}
              <div className="details-div">
                <span className="contact-name">
                  {/* {firstName + ' ' + lastName} */}
                  {app?.allOpenConversationDetails?.sender_name}
                </span>
                <div className="bottom-line-div">
                  {email?.data?.company_id !== null && (
                    <>
                      <span className="from">from</span>
                      <span className="company-name">
                        <TruncateText
                          text={email?.data?.company?.name}
                        ></TruncateText>
                      </span>
                      <span
                        className="link"
                        onClick={() => {
                          if (email?.data?.company?.id) {
                            const url = `/companies/${email.data.company.id}`;
                            window.open(url, "_blank");
                          } else {
                            console.error("Company ID is missing");
                          }
                        }}
                      >
                        <LuExternalLink
                          size={14}
                          color="var(--Gray-100, #556373)"
                        />
                      </span>

                      <span className="dot"></span>
                    </>
                  )}

                  <div className="from">Closed on</div>
                  <div
                    className="from-date"
                    style={{ width: "75px", whiteSpace: "nowrap" }}
                  >
                    Sep 4, 2024
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : selectedFolder == "Chat" || selectedFolder == "All Open" ? (
        <>
          {app?.allOpenConversationDetails?.avatar ? (
            <img
              src={app?.allOpenConversationDetails?.avatar}
              alt="Contact Avatar"
            />
          ) : (
            <AvatarPlaceholder>
              {getInitialsName(
                app?.allOpenConversationDetails?.sender_name != null
                  ? app?.allOpenConversationDetails?.sender_name
                  : app?.allOpenConversationDetails?.company_id != null
                  ? app?.allOpenConversationDetails?.company_name
                  : app?.allOpenConversationDetails?.guest_name
              )}
            </AvatarPlaceholder>
          )}
          <div className="details-div">
            <span className="contact-name">
              {app?.allOpenConversationDetails?.sender_name != null
                ? app?.allOpenConversationDetails?.sender_name
                : app?.allOpenConversationDetails?.company_id != null
                ? app?.allOpenConversationDetails?.company_name
                : app?.allOpenConversationDetails?.guest_name}
            </span>
            <div className="bottom-line-div">
              <span className="company-name">
                {app?.allOpenConversationDetails?.owner}
              </span>
              <span className="dot"></span>
              {app?.allOpenConversationDetails?.is_closed ? (
                <>
                  <span className="from">Closed on</span>
                  <span className="from">
                    {formatDate(app?.allOpenConversationDetails?.closed_at)}
                  </span>
                </>
              ) : (
                <>
                  <span className="from">Opened on</span>
                  <span className="from">
                    {formatDate(app?.allOpenConversationDetails?.created_at)}
                  </span>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        selectedEmails?.length <= 0 && (
          <>
            {email?.data && email?.data?.is_unknown_visitor ? (
              <>
                {avatar ? (
                  <img src={avatar} alt="Contact Avatar" />
                ) : (
                  <AvatarPlaceholder>
                    {getUnknownInitials(senderName, senderName)}
                  </AvatarPlaceholder>
                )}
                <div className="details-div">
                  <span className="contact-name">Unknown Visitor</span>
                  <div className="bottom-line-div">
                    <span
                      onClick={showDrawerContactDrawer}
                      style={{ cursor: "pointer" }}
                      className="company-name"
                    >
                      + Add contact
                    </span>
                    <span className="dot"></span>
                    {email?.data?.closed_at ? (
                      <>
                        <span className="from">Closed on</span>
                        <span className="from">
                          {formatDate(email?.data?.closed_at)}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="from">Opened on</span>
                        <span className="from">
                          {formatDate(email?.data?.created_at)}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                {avatar ? (
                  <img src={avatar} alt="Contact Avatar" />
                ) : (
                  <AvatarPlaceholder>
                    {getInitials(
                      app?.allOpenConversationDetails?.sender_name != null
                        ? app?.allOpenConversationDetails?.sender_name
                        : app?.allOpenConversationDetails?.company_id != null
                        ? app?.allOpenConversationDetails?.company_name
                        : app?.allOpenConversationDetails?.guest_name
                    )}
                  </AvatarPlaceholder>
                )}
                <div className="details-div">
                  <span className="contact-name">
                    {/* {firstName + ' ' + lastName} */}
                    {app?.allOpenConversationDetails?.sender_name != null
                      ? app?.allOpenConversationDetails?.sender_name
                      : app?.allOpenConversationDetails?.company_id != null
                      ? app?.allOpenConversationDetails?.company_name
                      : app?.allOpenConversationDetails?.guest_name}
                  </span>
                  <div className="bottom-line-div">
                    {email?.data?.company_id !== null && (
                      <>
                        <span className="from">from</span>
                        <span className="company-name">
                          <TruncateText
                            text={email?.data?.company?.name}
                          ></TruncateText>
                        </span>
                        <span
                          className="link"
                          onClick={() => {
                            if (email?.data?.company?.id) {
                              navigate(`/companies/${email.data.company.id}`);
                            } else {
                              console.error("Company ID is missing");
                            }
                          }}
                        >
                          <LuExternalLink
                            size={14}
                            color="var(--Gray-100, #556373)"
                          />
                        </span>
                        <span className="dot"></span>
                      </>
                    )}

                    <div className="from">Closed on</div>
                    <div
                      className="from-date"
                      style={{ width: "75px", whiteSpace: "nowrap" }}
                    >
                      Sep 4, 2024
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )
      )}
    </Container>
  );
};

export default ColumnHeader;
