import React, { useState, useEffect } from 'react'
import {
  Header,
  Body,
  HeaderTitle,
  MessageBodyContainer,
  MainConatiner,
} from './styles'
import Swal from 'sweetalert2'
import { Skeleton, Spin } from 'antd'
import useGetData from '../../../../hooks/useGetData'
import { FaExpandAlt, FaMinus, FaSquare } from 'react-icons/fa'
import { CrossIcon } from '../../../../common/customPill/styles'
import Avatar from '../../ThirdColumn/chatHeader/avatar/avatar'
import Chat from './chat/Chat'
import Commets from './commets/Commets'
import ChatInputComponent from '../../contractedChats/chatCard/chatInputComponent/ChatInputComponent'
import { useSelector, useDispatch } from 'react-redux'
import { InstanceWithAuth } from '../../../../App'
import ReactTostify from '../../../../common/ReactTostify'
import { useNavigate } from 'react-router-dom'
import { RiFullscreenLine } from 'react-icons/ri'
import { AiOutlineFullscreen } from 'react-icons/ai'
import { FaRegSquareFull } from 'react-icons/fa6'
import { handleRefetchMailThread } from '../../../../redux/app/appSlice'
const ChatCard = ({ emailId, removeEmailFromList }) => {
  const [selectedTab, setSelectedTab] = useState('Email')
  const { auth, app } = useSelector((state) => state)
  const [savingComment, setSavingComment] = useState(false)
  const [isVisible, setIsVisible] = useState(true)
  const [sendingMail, setSendingMail] = useState(false)
  const [editingNote, setEditingNote] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    data: email,
    error,
    loading,
    refetchData,
  } = useGetData(`support/emails/${app?.currentActiveChatThread}`)
  
  const { data: notes, refetchData: refetchNotes } = useGetData(
    `support/emails/notes/${app?.currentActiveChatThread}`
  )

  // if (error) {
  //   Swal.fire({
  //     title: "Error",
  //     text: `Failed to load email ID ${emailId}`,
  //     icon: "error",
  //     confirmButtonText: "Ok",
  //   });
  //   return null;
  // }
  useEffect(() => {
    refetchData()
    refetchNotes()
  }, [app?.isEmailThreadUpdated, app?.currentActiveChatThread])

  const handleRefetcThreads = () => {
    dispatch(handleRefetchMailThread(!app?.isEmailThreadUpdated))
  }
  const TruncateText = ({ text }) => {
    const maxLength = 25
    let truncatedText = text.substring(0, maxLength)
    if (truncatedText.length < text.length) {
      truncatedText += '...'
    }
    return <span title={text}>{truncatedText}</span>
  }

  const handleSend = async (data) => {
    setSendingMail(true)
    const { to, cc, bcc, message, files } = data
    const formData = new FormData()
    formData.append(
      'from_name',
      `${email?.data?.contact?.first_name} ${email?.data?.contact?.last_name}`
    )
    formData.append('message_body', message)
    to.forEach((recipient, index) => formData.append(`to[${index}]`, recipient))
    cc.forEach((recipient, index) => formData.append(`cc[${index}]`, recipient))
    bcc.forEach((recipient, index) =>
      formData.append(`bcc[${index}]`, recipient)
    )
    Array.from(files).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name)
    })
    try {
      const response = await InstanceWithAuth.post(
        `support/emails/reply/${emailId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${auth?.userObj?.api_token}`,
          },
        }
      )
      if (response.status === 201 || response.status === 200) {
        // ReactTostify("success", response?.data?.message);
        handleRefetcThreads()
      }
    } catch (error) {
    } finally {
      setSendingMail(false)
    }
  }

  const handleCommentSend = async (data) => {
    setSavingComment(true)
    const { comment, files } = data
    const formData = new FormData()
    formData.append(
      'from_name',
      `${email?.data?.contact?.first_name} ${email?.data?.contact?.last_name}`
    )
    formData.append('notes', comment)

    // if (editingNote) {
    //   formData.append(`attachments[]`, "");
    // } else {
    Array.from(files).forEach((file, index) => {
      formData.append(`attachments[${index}]`, file, file.name)
    })
    // }

    try {
      let response
      if (editingNote) {
        response = await InstanceWithAuth.post(
          `support/emails/notes/${editingNote.id}/update`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        )
      } else {
        response = await InstanceWithAuth.post(
          `support/emails/notes/${emailId}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${auth?.userObj?.api_token}`,
            },
          }
        )
      }

      if (response.status === 201 || response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response?.data?.message,
          customClass: {
            confirmButton: 'custom-swal-button',
          },
        })
        handleRefetcThreads()
      }
    } catch (error) {
    } finally {
      setSavingComment(false)
      setEditingNote(null)
    }
  }

  const handleEditNote = (note) => {
    setEditingNote(note)
  }

  return (
    <MainConatiner style={{ height: isVisible ? '500px' : 'auto' }}>
      <Header>
        <div className="make-row">
          <Avatar currentEmployeeImage={email?.data?.contact} />
          <HeaderTitle>
            <TruncateText text={email?.data?.subject || ''} />
          </HeaderTitle>
        </div>
        <div className="make-row">
          {isVisible ? (
            <FaMinus
              style={{ cursor: 'pointer', marginRight: '5px' }}
              size={16}
              onClick={() => setIsVisible(false)}
            />
          ) : (
            <FaRegSquareFull
              style={{ cursor: 'pointer', marginRight: '5px' }}
              size={16}
              onClick={() => setIsVisible(true)}
            />
          )}
          <FaExpandAlt
            style={{ cursor: 'pointer' }}
            size={16}
            onClick={() => {
              removeEmailFromList(emailId)
              localStorage.setItem('id', email?.data?.id)
              localStorage.setItem('folder', email?.data?.folder)
              navigate(`/support`)
            }}
          />
          <CrossIcon
            size={17}
            style={{ cursor: 'pointer', marginLeft: '1px' }}
            onClick={() => removeEmailFromList(emailId)}
          />
        </div>
      </Header>
      {isVisible && (
        <>
          <>
            <Body>
              {loading ? (
                <div style={{ width: '330px', padding: '10px' }}>
                  <Skeleton active />
                </div>
              ) : (
                <>
                  {/* {selectedTab === 'Email' ? ( */}
                  <Chat
                    email={email}
                    currentEmployeeImage={email?.data?.contact}
                    selectedEmailId={emailId}
                    refetchNotes={refetchNotes}
                    notes={notes}
                    onEdit={handleEditNote}
                  />
                  {/* ) : ( */}
                  {/* <Commets
                    selectedEmailId={emailId}
                    refetchNotes={refetchNotes}
                    notes={notes}
                    onEdit={handleEditNote}
                  /> */}
                  {/* )} */}_
                </>
              )}
            </Body>

            <ChatInputComponent
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              onSend={handleSend}
              handleCommentSend={handleCommentSend}
              savingComment={savingComment}
              email={email}
              sendingMail={sendingMail}
              notes={notes}
              editingNote={editingNote}
              loading={loading}
            />
          </>
        </>
      )}
    </MainConatiner>
  )
}

export default ChatCard
