import React, { useEffect, useState } from 'react'
import { ChatMessageContainer, AttachmentList } from './styles'
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'
import Avatar from '../avatar/avatar'
import CustomTooltip from '../../../allEmpolyeesComponents/AllEmpolyeesCard/customTooltip/CustomTooltip'
import MessageDetailsTooltip from './messageDetailsTooltip/MessageDetailsTooltip'
import GetLogo from '../../../getlogo/getlogo'
import { GoReply } from 'react-icons/go'
import { IoReturnUpForward } from 'react-icons/io5'
import { useSelector } from 'react-redux'
function ChatMessage({
  message,
  sender,
  timestamp,
  imageName,
  emailData,
  currentEmployeeImage,
  time,
  attachments,
  sender_avatar,
  handleForwardMail,
  handleReplyEmail,
  mailDetails,
}) {
  const [showTooltip, setShowTooltip] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [showFaded, setShowFaded] = useState(false)
  const [tooltipText, setTooltipText] = useState('Click to copy')
  const { app } = useSelector((state) => state)
  useEffect(() => {
    if (message.length > 150 && !expanded) {
      setShowFaded(true)
    } else {
      setShowFaded(false)
    }
  }, [expanded, message.length])

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  const TruncateText = ({ text }) => {
    const maxLength = 15
    let truncatedText = text.substring(0, maxLength)
    if (truncatedText.length < text.length) {
      truncatedText += '...'
    }
    return <span title={text}>{truncatedText}</span>
  }

  const handleCopy = (emailData) => {
    console.log(emailData, 'emailData')
    const url = `${window.location.origin}/support/${app?.currentActiveInboxTab}/${app?.currentActiveChatThread}/${emailData?.id}`
    navigator.clipboard.writeText(url)
    setTooltipText('Copied')
    setTimeout(() => {
      setTooltipText('Click to Copy')
    }, 2000)
  }

  return (
    <ChatMessageContainer
      expanded={expanded}
      showFaded={showFaded}
      sender={sender}
    >
      <div className="email-item">
        <div className="email-header">
          <Avatar sender_avatar={sender_avatar} sender={sender} />
          <div className="email-details">
            <div className="email-top-row">
              <div className="left-container">
                <span className="email-sender">{sender}</span>
                {showTooltip && (
                  <MessageDetailsTooltip
                    to={emailData.receiver_email}
                    subject={emailData.subject}
                    from={emailData.sender_email}
                    date={timestamp}
                    closeTooltip={() => setShowTooltip(false)}
                    marginTop={50}
                  >
                    <span className="tooltip-trigger">Details</span>
                  </MessageDetailsTooltip>
                )}
                <div
                  className="arrow-down"
                  onClick={() => setShowTooltip(!showTooltip)}
                >
                  {showTooltip ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
                </div>
              </div>

              <div className="make-row">
                <CustomTooltip marginTop="-15px" text={timestamp}>
                  <span className="email-time">{time}</span>
                </CustomTooltip>
                <CustomTooltip marginTop="-15px" text={'Reply'}>
                  <span className="back-icon">
                    <GoReply onClick={() => handleReplyEmail(emailData)} />
                  </span>
                </CustomTooltip>
                <CustomTooltip marginTop="-15px" text={'Forward'}>
                  <span className="back-icon">
                    <IoReturnUpForward
                      onClick={() => handleForwardMail(mailDetails)}
                    />
                  </span>
                </CustomTooltip>

                <CustomTooltip marginTop="-15px" text={tooltipText}>
                  <div className="icon" onClick={() => handleCopy(emailData)}>
                    {GetLogo('copy')}
                  </div>
                </CustomTooltip>
              </div>
            </div>

            <div
              className={`email-subject ${expanded ? 'expanded' : ''}`}
              dangerouslySetInnerHTML={{ __html: message }}
            />

            <AttachmentList>
              {attachments.map((file, index) => (
                <a
                  href={file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className="AttachmentItem"
                >
                  <div className="file-name">
                    <img className="file-image" src={file?.url} alt=" " />
                    <TruncateText text={file.filename}></TruncateText>
                  </div>
                  <span className="file-size">{file.formatted_size}</span>
                </a>
              ))}
            </AttachmentList>

            {message.length > 150 && (
              <div className="toggle-button">
                <div className="show-more" onClick={toggleExpand}>
                  {expanded ? (
                    <>
                      Show less{' '}
                      <span
                        style={{
                          paddingTop: '4px',
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <TiArrowSortedUp />
                      </span>
                    </>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 0,
                      }}
                    >
                      <span> Show more </span>
                      <span
                        style={{
                          padding: 0,
                          marginBottom: '-7px',
                        }}
                      >
                        <TiArrowSortedDown />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </ChatMessageContainer>
  )
}

export default ChatMessage
