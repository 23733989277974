import React, { useState } from 'react'
import { Collapse, Skeleton } from 'antd'
import { CompanyCards, Container, ContentContainer } from './styles'
import './styles'
import { useNavigate } from 'react-router-dom'
import useGetData from '../../../../../hooks/useGetData'
import DataNotFound from '../../../../../common/DataNotFound'
import FormatDate from '../../../../../common/FormatDate'
import GetLogo from '../../../../getlogo/getlogo'
import { useDispatch } from 'react-redux'
import { handSelectCompanyFlowTab } from '../../../../../redux/app/appSlice'
import StatusLabelButton from '../../../../statusLabelButton/StatusLabelButton'
const { Panel } = Collapse

export default function LiveAnswering({ userID, companyData }) {
  const {
    data: liveAnsweringData,
    loading,
    error: liveAnsweringDataErro,
  } = useGetData(`company-overview/live-answerings`)
  const [collopasedKey, setCollopasedKey] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const handleRedirect = () => {
    dispatch(handSelectCompanyFlowTab('6'))
    navigate(`/companies/${userID}`)
  }
  return (
    <Container open={collopasedKey}>
      <Collapse
        accordion
        className="account-collapase"
        onChange={() => setCollopasedKey(!collopasedKey)}
      >
        {console.log(liveAnsweringData, 'numberlll')}
        <Panel header="Live Answering" key="1" className="account-panel">
          <ContentContainer>
            {loading ? (
              <Skeleton active></Skeleton>
            ) : liveAnsweringData?.data?.length == 0 ? (
              <DataNotFound label={'No Company Found'} />
            ) : (
              liveAnsweringData?.data?.map((item, index) => (
                <CompanyCards>
                  <div className="company-labels">
                    <p className="company-name">
                      Live Answering Plan #{index + 1}
                    </p>
                    <span className="office"></span>
                  </div>
                  <div className="time-details">
                    <p className="date"> Last Edits 11/15/2023 06:01PM EST</p>
                    <div className="btns">
                      <StatusLabelButton
                        Data={companyData?.data}
                      ></StatusLabelButton>
                      <button
                        className="view-btn"
                        onClick={() => handleRedirect()}
                      >
                        <span className="btn-text">View</span>
                      </button>
                    </div>
                  </div>
                </CompanyCards>
              ))
            )}
            {/* <Collapse className="form-collapased">
              <Panel header="View All" key="1-1" className="panel">
                {loading ? (
                  <Skeleton active></Skeleton>
                ) : data?.data?.length == 0 ? (
                  <DataNotFound label={'No Company Found'} />
                ) : (
                  <CompanyCards>
                    <div className="company-labels">
                      <p className="company-name">Gonzalez LLC</p>
                      <span className="office">Premium Business Mailbox</span>
                    </div>
                    <div className="time-details">
                      <p className="date">11 October 2023</p>
                      <div className="btns">
                        <button className="active-btn">
                          <span className="btn-text">Active</span>
                        </button>
                        <button className="view-btn">
                          <span className="btn-text">View</span>
                        </button>
                      </div>
                    </div>
                  </CompanyCards>
                )}
              </Panel>
            </Collapse> */}
          </ContentContainer>
        </Panel>
      </Collapse>
    </Container>
  )
}
